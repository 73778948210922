// config/api.js

// Danh sách các API keys
export const API_KEYS = [
    'AIzaSyA7IVH7zDiA-x9zotfWCdco9TeheVZhyAY',
    // Thêm các key khác vào đây
    'AIzaSyCwqn6wSSioSBhSYYkjCoUffgQ1VvcvZvo',
    'AIzaSyBvAuFiwDJcRy9-vTq0Q9a0tHmx_0svZeA'
  ];
  
  // Theo dõi việc sử dụng key
  let currentKeyIndex = 0;
  let keyUsageCount = new Map(API_KEYS.map(key => [key, 0]));
  const DAILY_LIMIT = 1500; // Giới hạn request mỗi ngày cho free tier
  
  export const getNextApiKey = () => {
    currentKeyIndex = (currentKeyIndex + 1) % API_KEYS.length;
    return API_KEYS[currentKeyIndex];
  };
  
  export const getCurrentApiKey = () => API_KEYS[currentKeyIndex];
  
  export const resetDailyUsage = () => {
    keyUsageCount = new Map(API_KEYS.map(key => [key, 0]));
  };
  
  export const GEMINI_API_URL = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';
  
  // Hàm kiểm tra và xử lý key rotation
  export const handleApiKeyRotation = async (error) => {
    const currentKey = getCurrentApiKey();
    const currentUsage = keyUsageCount.get(currentKey) || 0;
  
    // Kiểm tra các điều kiện để switch key
    if (
      error?.message?.includes('quota exceeded') ||
      error?.message?.includes('invalid key') ||
      currentUsage >= DAILY_LIMIT
    ) {
      // Tăng số lượt sử dụng của key hiện tại
      keyUsageCount.set(currentKey, currentUsage + 1);
      
      // Lấy key mới
      const newKey = getNextApiKey();
      
      // Nếu đã thử hết tất cả các key
      if (newKey === currentKey) {
        throw new Error('All API keys have reached their limits');
      }
      
      return newKey;
    }
    
    return currentKey;
  };
  
  // Wrapper function để tự động xử lý key rotation
  export const executeWithKeyRotation = async (apiCall) => {
    let attempts = 0;
    const maxAttempts = API_KEYS.length;
  
    while (attempts < maxAttempts) {
      try {
        const result = await apiCall(getCurrentApiKey());
        return result;
      } catch (error) {
        attempts++;
        const newKey = await handleApiKeyRotation(error);
        
        if (attempts === maxAttempts) {
          throw new Error('All API keys have failed');
        }
        
        // Log để debug
        console.log(`Switching to new API key: ${newKey}`);
      }
    }
  };