import { executeWithKeyRotation, GEMINI_API_URL } from '../config/api';

export const transformGeminiResponse = (geminiResponse) => {
    try {
      // Giữ nguyên phần transform response như cũ
      const analysisText = geminiResponse.candidates[0].content.parts[0].text;
      const jsonMatch = analysisText.match(/```json\n([\s\S]*)\n```/);
      
      if (!jsonMatch) {
        throw new Error('Could not find JSON in response');
      }
      
      const parsedResponse = JSON.parse(jsonMatch[1]);
      
      const defaultAnalysis = {
        name: '',
        characteristics: [],
        materials: [],
        colorScheme: [
          { name: 'Màu chủ đạo', colors: [] },
          { name: 'Màu nhấn', colors: [] }
        ],
        furniture: [],
        spaceUsage: [],
        lightingAnalysis: [],
        improvements: []
      };
  
      const transformedResponse = {
        ...defaultAnalysis,
        ...parsedResponse
      };
  
      if (!transformedResponse.furniture || !Array.isArray(transformedResponse.furniture)) {
        transformedResponse.furniture = [{
          name: '',
          material: '',
          color: '',
          quantity: '',
          notes: ''
        }];
      }
  
      transformedResponse.furniture = transformedResponse.furniture.map(item => ({
        name: item.name || '',
        material: item.material || '',
        color: item.color || '',
        quantity: item.quantity || '',
        notes: item.notes || ''
      }));
  
      if (!Array.isArray(transformedResponse.colorScheme)) {
        transformedResponse.colorScheme = defaultAnalysis.colorScheme;
      }
  
      ['characteristics', 'materials', 'spaceUsage', 'lightingAnalysis', 'improvements'].forEach(field => {
        if (!Array.isArray(transformedResponse[field])) {
          transformedResponse[field] = [];
        }
      });
  
      return transformedResponse;
    } catch (error) {
      console.error('Error transforming Gemini response:', error);
      throw new Error('Failed to transform API response: ' + error.message);
    }
};

export const analyzeInteriorWithGemini = async (imageBase64, language = 'vi') => {
  return executeWithKeyRotation(async (apiKey) => {
    const prompt = `Analyze this interior design image and provide a detailed analysis ${language === 'vi' ? 'in Vietnamese' : 'in English'}:

    Return the response in this exact JSON format:
    {
      "name": "Style name",
      "characteristics": ["point1", "point2", ...],
      "materials": ["material1", "material2", ...],
      "colorScheme": [
        {"name": "Main colors", "colors": ["#hexcode1", "#hexcode2"]},
        {"name": "Accent colors", "colors": ["#hexcode1", "#hexcode2"]}
      ],
      "furniture": [
        {"name": "Item name", "material": "Material", "color": "Color", "quantity": "1", "notes": "Additional notes"},
        ...
      ],
      "spaceUsage": ["How space is utilized", ...],
      "lightingAnalysis": ["Lighting details", ...],
      "improvements": ["Suggestion 1", ...]
    }`;

    const response = await fetch(`${GEMINI_API_URL}?key=${apiKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contents: [{
          parts: [
            { text: prompt },
            {
              inlineData: {
                mimeType: "image/jpeg",
                data: imageBase64.split(',')[1]
              }
            }
          ]
        }]
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || 'API request failed');
    }

    const data = await response.json();
    return transformGeminiResponse(data);
  });
};