import React, { useState, useCallback } from 'react';
import { 
  Upload, 
  PaintBucket, 
  Layout, 
  Box, 
  Layers, 
  Loader, 
  Languages, 
  Edit, 
  Save, 
  X, 
  Lightbulb, 
  Table 
} from 'lucide-react';
import { analyzeInteriorWithGemini } from './services/geminiService';

const App = () => {
  const [image, setImage] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('vi');
  const [editMode, setEditMode] = useState(false);
  const [editedAnalysis, setEditedAnalysis] = useState(null);

  const analyzeImage = useCallback(async (file) => {
    if (!file) return;

    setLoading(true);
    setError(null);
    
    try {
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Kích thước file không được vượt quá 5MB');
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target.result);
        try {
          const result = await analyzeInteriorWithGemini(e.target.result, language);
          setAnalysis(result);
          setEditedAnalysis(result);
        } catch (err) {
          setError('Có lỗi xảy ra khi phân tích ảnh: ' + err.message);
        } finally {
          setLoading(false);
        }
      };

      reader.onerror = () => {
        setError('Không thể đọc file. Vui lòng thử lại');
        setLoading(false);
      };

      reader.readAsDataURL(file);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [language]);

  // Components
  const ColorPalette = ({ colors, onEdit }) => (
    <div className="flex gap-2 mt-2">
      {colors.map((color, index) => (
        <div
          key={index}
          className="relative group"
        >
          <div
            className="w-8 h-8 rounded-full border shadow-sm cursor-pointer hover:scale-110 transition-transform"
            style={{ backgroundColor: color }}
            title={color}
            onClick={() => navigator.clipboard.writeText(color)}
          />
          {editMode && (
            <input
              type="color"
              value={color}
              onChange={(e) => onEdit(index, e.target.value)}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
          )}
        </div>
      ))}
    </div>
  );

  const EditableList = ({ items, onChange, type = 'text' }) => (
    <ul className="list-disc pl-5 space-y-1">
      {items.map((item, index) => (
        <li key={index} className="text-gray-600">
          {editMode ? (
            <input
              type={type}
              value={item}
              onChange={(e) => {
                const newItems = [...items];
                newItems[index] = e.target.value;
                onChange(newItems);
              }}
              className="w-full p-1 border rounded"
            />
          ) : item}
        </li>
      ))}
    </ul>
  );

  const FurnitureTable = ({ furniture, onEdit }) => (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tên</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vật liệu</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Màu sắc</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Số lượng</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ghi chú</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {furniture.map((item, index) => (
            <tr key={index}>
              {Object.keys(item).map((key) => (
                <td key={key} className="px-4 py-2">
                  {editMode ? (
                    <input
                      type="text"
                      value={item[key]}
                      onChange={(e) => {
                        const newFurniture = [...furniture];
                        newFurniture[index] = {
                          ...newFurniture[index],
                          [key]: e.target.value
                        };
                        onEdit(newFurniture);
                      }}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    item[key]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      analyzeImage(file);
    } else {
      setError('Vui lòng chọn file hình ảnh');
    }
  }, [analyzeImage]);
  
  const handleFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      analyzeImage(file);
    } else {
      setError('Vui lòng chọn file hình ảnh');
    }
  }, [analyzeImage]);
  
  // Thêm hàm xử lý kéo thả
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);
  
  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);
  
  const handleSaveEdit = () => {
    setAnalysis(editedAnalysis);
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditedAnalysis(analysis);
    setEditMode(false);
  };

  // Main render
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-9xl mx-auto px-4">
        <div className="mb-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold text-gray-900 flex items-center gap-2">
              <Layout className="w-8 h-8" />
              Phân tích thiết kế nội thất với AI
            </h1>
            <div className="flex items-center gap-4">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="p-2 border rounded"
              >
                <option value="vi">Tiếng Việt</option>
                <option value="en">English</option>
              </select>
              {analysis && !loading && (
                <button
                  onClick={() => setEditMode(!editMode)}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {editMode ? <Save className="w-4 h-4" /> : <Edit className="w-4 h-4" />}
                  {editMode ? 'Lưu' : 'Chỉnh sửa'}
                </button>
              )}
            </div>
          </div>
          {error && (
            <p className="text-red-500 mt-2">{error}</p>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Upload section */}
          <div className="space-y-6">
          <div
  className={`border-2 border-dashed rounded-xl p-8 text-center transition-colors ${
    isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
  } hover:border-blue-400 cursor-pointer`}
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  onClick={() => document.getElementById('file-input').click()}
>
  <input
    id="file-input"
    type="file"
    className="hidden"
    accept="image/*"
    onChange={handleFileSelect}
  />
  <Upload className="w-12 h-12 mx-auto mb-4 text-gray-400" />
  <p className="text-gray-600">
    Kéo thả hình ảnh vào đây hoặc click để chọn file
  </p>
  <p className="text-gray-400 text-sm mt-2">
    Hỗ trợ: JPG, PNG (Tối đa 5MB)
  </p>
</div>

            {image && (
              <div className="relative aspect-video rounded-lg overflow-hidden group">
                <img
                  src={image}
                  alt="Interior"
                  className="object-cover w-full h-full"
                />
              </div>
            )}
          </div>

          {/* Analysis section */}
          <div className="bg-white p-6 rounded-xl shadow-sm min-h-[400px]">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="flex flex-col items-center gap-3">
                  <Loader className="w-8 h-8 animate-spin text-blue-500" />
                  <p className="text-gray-500">Đang phân tích hình ảnh...</p>
                </div>
              </div>
            ) : editedAnalysis ? (
              <div className="space-y-6">
                {/* Style Name */}
                <div>
                  <h2 className="text-xl font-semibold flex items-center gap-2">
                    <Layout className="w-5 h-5" />
                    {editMode ? (
                      <input
                        type="text"
                        value={editedAnalysis.name}
                        onChange={(e) => setEditedAnalysis({
                          ...editedAnalysis,
                          name: e.target.value
                        })}
                        className="w-full p-1 border rounded"
                      />
                    ) : editedAnalysis.name}
                  </h2>
                </div>

                {/* Characteristics */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Layers className="w-4 h-4" />
                    Đặc điểm nổi bật
                  </h3>
                  <EditableList
                    items={editedAnalysis.characteristics}
                    onChange={(newItems) => setEditedAnalysis({
                      ...editedAnalysis,
                      characteristics: newItems
                    })}
                  />
                </div>

                {/* Materials */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Box className="w-4 h-4" />
                    Vật liệu chính
                  </h3>
                  <EditableList
                    items={editedAnalysis.materials}
                    onChange={(newItems) => setEditedAnalysis({
                      ...editedAnalysis,
                      materials: newItems
                    })}
                  />
                </div>

                {/* Color Scheme */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <PaintBucket className="w-4 h-4" />
                    Bảng màu
                  </h3>
                  {editedAnalysis.colorScheme.map((scheme, index) => (
                    <div key={index} className="mb-4">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        {scheme.name}
                      </h4>
                      <ColorPalette
                        colors={scheme.colors}
                        onEdit={(colorIndex, newColor) => {
                          const newScheme = [...editedAnalysis.colorScheme];
                          newScheme[index].colors[colorIndex] = newColor;
                          setEditedAnalysis({
                            ...editedAnalysis,
                            colorScheme: newScheme
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>

      {/* Furniture Table */}
      <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Table className="w-4 h-4" />
                    Danh sách nội thất
                  </h3>
                  <FurnitureTable
                    furniture={editedAnalysis.furniture}
                    onEdit={(newFurniture) => setEditedAnalysis({
                      ...editedAnalysis,
                      furniture: newFurniture
                    })}
                  />
                </div>

                {/* Space Usage */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Layout className="w-4 h-4" />
                    Cách sử dụng không gian
                  </h3>
                  <EditableList
                    items={editedAnalysis.spaceUsage}
                    onChange={(newItems) => setEditedAnalysis({
                      ...editedAnalysis,
                      spaceUsage: newItems
                    })}
                  />
                </div>

                {/* Lighting Analysis */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Lightbulb className="w-4 h-4" />
                    Phân tích ánh sáng
                  </h3>
                  <EditableList
                    items={editedAnalysis.lightingAnalysis}
                    onChange={(newItems) => setEditedAnalysis({
                      ...editedAnalysis,
                      lightingAnalysis: newItems
                    })}
                  />
                </div>

                {/* Improvements */}
                <div>
                  <h3 className="font-medium flex items-center gap-2 mb-3">
                    <Lightbulb className="w-4 h-4" />
                    Đề xuất cải thiện
                  </h3>
                  <EditableList
                    items={editedAnalysis.improvements}
                    onChange={(newItems) => setEditedAnalysis({
                      ...editedAnalysis,
                      improvements: newItems
                    })}
                  />
                </div>

                {/* Edit Mode Controls */}
                {editMode && (
                  <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
                    <button
                      onClick={handleCancelEdit}
                      className="flex items-center gap-2 px-4 py-2 border rounded hover:bg-gray-50"
                    >
                      <X className="w-4 h-4" />
                      Hủy
                    </button>
                    <button
                      onClick={handleSaveEdit}
                      className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      <Save className="w-4 h-4" />
                      Lưu thay đổi
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;